import React from "react";
import useAuth from "../../hooks/useAuth";
import FirstLogin from "../../features/auth/FirstLogin";
import Songs from "../../pages/Songs";
import PWAPrompt from "react-ios-pwa-prompt";

function Homepage() {
  const { email, chestionar, id, active } = useAuth();

  return (
    <>
      <PWAPrompt
        copyAddHomeButtonLabel="2) Apasa 'Add to Home Screen'."
        copyBody="Adăugați aplicatia pe ecranul dispozitivului dvs."
        copyClosePrompt="Inchide"
        copyShareButtonLabel="1) Apasa 'Share' button on the menu bar below."
        copyTitle="Adauga in Home Screen"
        delay={1000}
        onClose={function Ki() {}}
        permanentlyHideOnDismiss
        promptLocalStorageKey="iosPwaPrompt"
        promptOnVisit={1}
        timesToShow={3}
        transitionDuration={400}
      />
      {chestionar === false && (
        <FirstLogin email={email} id={id} active={active} />
      )}
      <Songs />
    </>
  );
}

export default Homepage;
