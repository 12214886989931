import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const chestionarAdapter = createEntityAdapter({
  sortComparer: (a, b) =>
    a.completed === b.completed ? 0 : a.completed ? 1 : -1,
});

const initialState = chestionarAdapter.getInitialState();

export const chestionarApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getChestionar: builder.query({
      query: (id) => ({
        url: `/chestionar/${id}`,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Chestionar", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Chestionar", id })),
          ];
        } else return [{ type: "Chestionar", id: "LIST" }];
      },
    }),
    addNewChestionar: builder.mutation({
      query: (initialChestionar) => ({
        url: "/chestionar",
        method: "POST",
        body: {
          ...initialChestionar,
        },
      }),
      invalidatesTags: [{ type: "Chestionar", id: "LIST" }],
    }),
    updateChestionar: builder.mutation({
      query: (initialChestionar) => ({
        url: "/chestionar",
        method: "PATCH",
        body: {
          ...initialChestionar,
        },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Chestionar", id: arg.id },
      ],
    }),
    deleteChestionar: builder.mutation({
      query: ({ id }) => ({
        url: `/chestionar`,
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "Chestionar", id: arg.id },
      ],
    }),
  }),
});

export const {
  useGetChestionarQuery,
  useAddNewChestionarMutation,
  useUpdateChestionarMutation,
  useDeleteChestionarMutation,
} = chestionarApiSlice;

// returns the query result object
export const selectChestionarResult =
  chestionarApiSlice.endpoints.getChestionar.select();

// creates memoized selector
const selectChestionarData = createSelector(
  selectChestionarResult,
  (chestionarResult) => chestionarResult.data // normalized state object with ids & entities
);

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
  selectAll: selectAllChestionar,
  selectById: selectChestionarById,
  selectIds: selectChestionarIds,
  // Pass in a selector that returns the chestionar slice of state
} = chestionarAdapter.getSelectors(
  (state) => selectChestionarData(state) ?? initialState
);
